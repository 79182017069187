import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ethers, utils } from 'ethers';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setWallet } from '../../../../redux/auth/actions';
const MetaMaskSection = () => {
    const dispatch = useDispatch();
    // const walletAddresss = useSelector((state) => state.auth.walletAddress);
    const [walletAddress, setWalletAddress] = useState();

    const handleConnect = async () => {
        if (!window.ethereum) {
            toast.error('No crypto wallet found. Please install it.');
            const redirectPage = () => {
                let url = 'https://metamask.io/download/'
                window.open(url, '_blank')
            }
            setTimeout(() => redirectPage(), 2000)

        } else {
            const account = await window.ethereum.request({
                method: 'eth_requestAccounts'
            });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const { chainId } = await provider.getNetwork();
            if (chainId === 8002) {
                setWalletAddress(account[0]);
            } else {
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: `0x${Number(80002).toString(16)}` }],
                    });
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const { chainId } = await provider.getNetwork();
                    setWalletAddress(account[0]);
                } catch (error) {
                    toast.error(error.message)
                }
            }
        }
    };

    useEffect(() => {
        handleConnect();
        dispatch(setWallet(walletAddress));
    }, [walletAddress]);
    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    handleConnect();
                }}
            >
                {walletAddress ? walletAddress.slice(0, 5) + '...' + walletAddress.slice(38, 42) : 'Connect Metamask'}
            </Button>
        </>
    );
};

export default MetaMaskSection;
